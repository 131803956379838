<template>
    <div :class="block_class">
        <component 
            v-for="(column,index) in columns" :key="index"
            :is="getComponent(column)" 
            :data="data[index]" @change="updateColumn($event,index)" 
            :content="content"
        />
    </div>
</template>

<script>
import { computed } from 'vue'
export default {
    name: "BlockDefault",
    props: {
        content: {
            type: Object,
            required: true
        },
        components: {
            type: Object,
            required: true,
        }
    },
    emits: ['updateBlock'],
    setup(props,context) {
        const columns = computed(() => {
            let _array = [];
            if(!props.content || typeof props.content.typeCode !== 'string'){
                return _array;
            }
            let cells = props.content.typeCode.split('_');

            for(let i = 1; i < cells.length; i++){
                _array.push(cells[i]);
            }

            return _array;
        });

        const data = computed(() => {
            let _data;
            try {
                _data = JSON.parse(props.content.data);
            }
            catch (e) {
                _data = [{}];
            }
            if(!Array.isArray(_data) || _data.length < 1){
                _data = [{}];
            }
            for(let i = 1; i < columns.value.length+1; i++){
                if(_data.length < i){
                    _data.push({});
                }
            }
            _data.length = columns.value.length;
            return _data;
        });


        const block_class = computed(() => {
            if(columns.value.length === 2){
                return 'columns2';
            }
            if(columns.value.length === 3){
                return 'columns3';
            }
            return "";
        });

        const getComponent = (type) => props.components[type] ?? null;

        
        const updateColumn = (columnData,index) => {
            let _data = data.value;
            _data[index] = columnData;
            context.emit('updateBlock',JSON.stringify(_data))
        }


        return {
            columns,
            block_class,
            data,
            getComponent,
            updateColumn
        };
    }
}
</script>

<style scoped>

.columns2,.columns3{
    display: flex;
    flex-wrap: nowrap;
}
.columns2>div{
    width: 50%;
}
.columns3>div{
    width: 33%;
}


@media only screen and (max-width: 800px) {
    .columns3{
        flex-direction: column;
    }
    
    .columns3>div{
        width: 100%;
    }
}
@media only screen and (max-width: 600px) {
    .columns2{
        flex-direction: column;
    }
    
    .columns2>div{
        width: 100%;
    }
}

</style>
